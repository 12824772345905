<template>
  <div class="home">
<div class="overlay">
    <div class="overlay__grid">
      <div class="overlay__close"></div>
      <div class="overlay__signup">
        <a href="https://lessonpro.co.uk/contact-us">
          Contact
        </a>
      </div>
    </div>
    <nav class="overlay__nav">
      <ul class="menu">
        <li><a href="https://lessonpro.co.uk/why-lesson-pro">Why Lesson Pro</a></li>
        <li><a href="https://lessonpro.co.uk/pricing/">Pricing</a></li>
        <li><a href="https://lessonpro.co.uk/support/">Support</a></li>
        <li><a href="https://lessonpro.co.uk/news/">News</a></li>
      </ul>
    </nav>
    <div class="overlay__info">
        <a class="email" href="mailto:info@lessonpro.co.uk">info@lessonpro.co.uk</a>
      <div class="overlay__info__socials">
        <ul>
          <li><a class="twitter" href="https://twitter.com/lessonprogolf" target="_blank"></a></li>
          <li><a class="facebook" href="https://www.facebook.com/lessonprogolf" target="_blank"></a></li>
          <li><a class="instagram" href="https://www.instagram.com/lessonprogolf/" target="_blank"></a></li>
          <li><a class="youtube" href="https://www.youtube.com/channel/UCgDQjjmj75tKg2N2qKpbNUQ" target="_blank"></a></li>
        </ul>
      </div>
    </div>
  </div>

  <navigation />
  <div class="prop"></div>

  <main class="content white">
    <div class="container">
      <div class="content__grid">

        <div class="content__grid__col1">
          <div class="content__subtitle">
            Golf Professional Sign Up
          </div>
          <div class="content__title">
            Let's get you signed up!
          </div>
          <p>
            Please enter your details here to create your Lesson Pro and Stripe 
            accounts. Once completed, download the Lesson Pro App set up your 
            subscription and you’re ready to start inviting your Players.
          </p>
          <img src="@/assets/1.png" alt="">
        </div>
        <div class="content__grid__col2">

          <div class="content__form">
            <div class="form__progress step-1">
              <p>Step {{form.step}} of 5:</p>
              <div class="form__progress__outer">
                <div class="form__progress__inner" :style="{width:(form.step * 20)+10+'%'}"></div>
              </div>
            </div>
            
            <form @submit.prevent="sendForm">
            <div v-if="form.step == 1">

                <label for="">Personal Details</label>
                <input type="text" :class="{ 'invalid': form.errors.has('first_name') }" placeholder="First Name" v-model="form.first_name">
                <input type="text" placeholder="Surname" :class="{ 'invalid': form.errors.has('last_name') }" v-model="form.last_name">
                <input type="email" placeholder="Email" v-model="form.email" :class="{ 'invalid': form.errors.has('email') }">
                <has-error :form="form" field="email"/>
                <vue-phone-number-input
                  @update="setPhone"
                  v-model="phoneInput"
                  :fetch-country="false"
                  :no-use-browser-locale="false"
                ></vue-phone-number-input>
                <has-error :form="form" field="phone"/>

                <label for="timezone" style="margin: 20px 0 0 0;">Timezone</label>
                <select name="timezone_offset" id="timezone-offset" v-model="form.timezone" style="margin-top: 5px;">
                  <option value="" disabled>Please select a timezone</option>
                  <option value="-12:00">(GMT -12:00) Eniwetok, Kwajalein</option>
                  <option value="-11:00">(GMT -11:00) Midway Island, Samoa</option>
                  <option value="-10:00">(GMT -10:00) Hawaii</option>
                  <option value="-09:50">(GMT -9:30) Taiohae</option>
                  <option value="-09:00">(GMT -9:00) Alaska</option>
                  <option value="-08:00">(GMT -8:00) Pacific Time (US &amp; Canada)</option>
                  <option value="-07:00">(GMT -7:00) Mountain Time (US &amp; Canada)</option>
                  <option value="-06:00">(GMT -6:00) Central Time (US &amp; Canada), Mexico City</option>
                  <option value="-05:00">(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</option>
                  <option value="-04:50">(GMT -4:30) Caracas</option>
                  <option value="-04:00">(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</option>
                  <option value="-03:50">(GMT -3:30) Newfoundland</option>
                  <option value="-03:00">(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
                  <option value="-02:00">(GMT -2:00) Mid-Atlantic</option>
                  <option value="-01:00">(GMT -1:00) Azores, Cape Verde Islands</option>
                  <option value="+00:00">(GMT) Western Europe Time, London, Lisbon, Casablanca</option>
                  <option value="+01:00">(GMT +1:00) Brussels, Copenhagen, Madrid, Paris</option>
                  <option value="+02:00">(GMT +2:00) Kaliningrad, South Africa</option>
                  <option value="+03:00">(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
                  <option value="+03:50">(GMT +3:30) Tehran</option>
                  <option value="+04:00">(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
                  <option value="+04:50">(GMT +4:30) Kabul</option>
                  <option value="+05:00">(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</option>
                  <option value="+05:50">(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</option>
                  <option value="+05:75">(GMT +5:45) Kathmandu, Pokhara</option>
                  <option value="+06:00">(GMT +6:00) Almaty, Dhaka, Colombo</option>
                  <option value="+06:50">(GMT +6:30) Yangon, Mandalay</option>
                  <option value="+07:00">(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
                  <option value="+08:00">(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
                  <option value="+08:75">(GMT +8:45) Eucla</option>
                  <option value="+09:00">(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
                  <option value="+09:50">(GMT +9:30) Adelaide, Darwin</option>
                  <option value="+10:00">(GMT +10:00) Eastern Australia, Guam, Vladivostok</option>
                  <option value="+10:50">(GMT +10:30) Lord Howe Island</option>
                  <option value="+11:00">(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
                  <option value="+11:50">(GMT +11:30) Norfolk Island</option>
                  <option value="+12:00">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
                  <option value="+12:75">(GMT +12:45) Chatham Islands</option>
                  <option value="+13:00">(GMT +13:00) Apia, Nukualofa</option>
                  <option value="+14:00">(GMT +14:00) Line Islands, Tokelau</option>
                </select>
                <has-error :form="form" field="timezone"/>

            </div>

            <div v-if="form.step == 2">
                <label for="">Address</label>
                <input type="text" placeholder="Address line 1" v-model="form.address_line1" :class="{ 'invalid': form.errors.has('address_line1') }">
                <input type="text" placeholder="Address line 2" v-model="form.address_line2" :class="{ 'invalid': form.errors.has('address_line2') }">
                <input type="text" placeholder="Town / City" v-model="form.address_city" :class="{ 'invalid': form.errors.has('address_city') }">
                <input type="text" placeholder="Postcode" v-model="form.address_postcode" :class="{ 'invalid': form.errors.has('address_postcode') }">
                <select v-model="form.address_country" :class="{ 'invalid': form.errors.has('address_country') }">
                  <option value="">-- Choose country --</option>
                  <option value="AU">Australia</option>
                  <option value="AT">Austria</option>
                  <option value="BE">Belgium</option>
                  <option value="BR">Brazil</option>
                  <option value="BG">Bulgaria</option>
                  <option value="CA">Canada</option>
                  <option value="HR">Croatia</option>
                  <option value="CY">Cyprus</option>
                  <option value="CZ">Czech Republic</option>
                  <option value="DK">Denmark</option>
                  <option value="EE">Estonia</option>
                  <option value="FI">Finland</option>
                  <option value="FR">France</option>
                  <option value="DE">Germany</option>
                  <option value="GR">Greece</option>
                  <option value="HK">Hong Kong SAR China</option>
                  <option value="HU">Hungary</option>
                  <option value="IE">Ireland</option>
                  <option value="IT">Italy</option>
                  <option value="JP">Japan</option>
                  <option value="LV">Latvia</option>
                  <option value="LT">Lithuania</option>
                  <option value="LU">Luxembourg</option>
                  <option value="MY">Malaysia</option>
                  <option value="MT">Malta</option>
                  <option value="MX">Mexico </option>
                  <option value="NL">Netherlands</option>
                  <option value="NZ">New Zealand</option>
                  <option value="NO">Norway</option>
                  <option value="PL">Poland</option>
                  <option value="PT">Portugal</option>
                  <option value="RO">Romania</option>
                  <option value="SG">Singapore</option>
                  <option value="SK">Slovakia</option>
                  <option value="SI">Slovenia</option>
                  <option value="ES">Spain</option>
                  <option value="SE">Sweden</option>
                  <option value="CH">Switzerland</option>
                  <option value="TH">Thailand</option>
                  <option value="AE">United Arab Emirates</option>
                  <option value="GB">United Kingdom</option>
                  <option value="US">United States</option>
                </select>
            </div>

            <div v-if="form.step == 3">
          

              <label for="">Add the preferred lesson duration you would like to highlight to players when booking.</label>
              <select v-model="form.lesson_duration" :class="{ 'invalid': form.errors.has('lesson_duration') }">
                <option value="">-- Choose duration --</option>
                <option value="15">15 minutes</option>
                <option value="30">30 minutes</option>
                <option value="45">45 minutes</option>
                <option value="60">60 minutes</option>
                <option value="90">90 minutes</option>
                <option value="120">120 minutes</option>
              </select>

               <label for="">Add the value of 1 lesson credit (1 lesson credit = 15 minutes of your time).</label>
               <currency-input v-model="form.lesson_price" :class="{ 'invalid': form.errors.has('lesson_price') }" placeholder="Add price" :value-as-integer="true"/>


               <div class="creditExample">
               

                  <p>
                    <span class="title">Credit value example</span>
                    1 lesson credit = 15 minutes at a value of £{{(form.lesson_price / 100).toFixed(2)}}.<br>
                  Therefore 2 credits = 30 minutes at £{{((form.lesson_price / 100) * 2).toFixed(2)}}<br>
                    3 credits = 45 mins at £{{((form.lesson_price / 100) * 3).toFixed(2)}} and so on. 
                  </p>
               </div>
          
             

            </div>

            <div v-if="form.step == 4" class="clearfix">

             

              <label for="">Password:</label>
            <p>At least 8 characters including 1 letter, 1 number & 1 uppercase character.</p>
 
             <password-input placeholder="Password" v-model="form.password" :class="{ 'invalid': form.errors.has('password') }"/>
            <has-error :form="form" field="password"/>
           

            <password-input placeholder="Repeat Password" v-model="form.password_confirmation" :class="{ 'invalid': form.errors.has('password_confirmation') }"/>

            <div class="form__checkbox">
              <label for="terms">
                <input type="checkbox" id="terms" name="terms" v-model="form.terms">
                <span>I agree to Lesson Pro terms</span>
              </label>
            </div>
            <p><a href="https://lessonpro.co.uk/terms/" target="_blank">View Lesson Pro terms</a></p>
               
            </div>

                 <has-error :form="form" field="terms"/>
              <button :disabled="form.busy" v-if="form.step !== 5" type="submit">{{(form.busy) ? 'Please wait...' : 'Next'}}</button>
          

            </form>


            <div v-if="form.step == 5" class="clearfix">

                 <label>Almost done!</label>
                 <label>Click the button below to be taken to Stripe (our payment provider). Fill in your details with them so that you can get paid for your lessons into your bank account.</label>
                  <br>
                 <a :href="stripe_url" class="button btn--arrow-right btn--secondary">Take me to Stripe</a>

            </div>

            

            

            

            

          </div>

        </div>
      </div>
    </div>
  </main>

  <footer-nav />
  </div>
</template>

<script>
import PasswordInput from '@/components/password-input'
import FooterNav from '@/components/footer-nav'
import Navigation from '@/components/navigation'
import { Form, HasError } from 'vform';
export default {
  name: 'Home',
  components: {PasswordInput, HasError, Navigation,FooterNav },
  data(){
    return {
      stripe_url: '',
      phoneInput: '',
      form: new Form({
        step: 1,
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        timezone: '',
        address_line1: '',
        address_line2: '',
        address_city: '',
        address_country: 'GB',
        address_postcode: '',
        password: '',
        password_confirmation: '',
        terms: false,
        lesson_duration: '',
        lesson_price: null
      })
    }
  },
  methods: {
    setPhone(data) {
      this.form.phone = data.formatInternational;
    },
    sendForm(){
      this.form.post('/pro-signup').then((response)=>{
        if(this.form.step == 1){
          this.form.step = 2;
        } else if(this.form.step == 2){
          this.form.step = 3;
        } else if(this.form.step == 3){
           this.form.step = 4;
        } else if(this.form.step == 4){
          this.stripe_url = response.data.url;
          console.log(response);
          this.form.step = 5;
        }
      })
    }
  }
}
</script>

<style>
.creditExample{
  background:#baf8e1;
  padding: 20px;
  padding-top: 14px;
  padding-bottom: 14px;
  border-radius: 12px;
  display: block;
  color: #267962;
  line-height: 1.5;
  font-size: 14px;
  min-height: 52px;

}
.creditExample .title{
font-size:20px;
display:block;
margin-bottom: 20px;
}

.form-styles label, .content__form label.country-selector__label{
  margin-top: 0;
  font-size: 12px !important;
  top: 0;
}
.form-styles label, .content__form label.input-tel__label{
  margin-top: 0;
  font-size: 12px !important;
  top: 0;
}
.dots-text{
  color: #000;
}
button.country-selector__list__item{
  margin-top: 0 !important;
}
</style>
