<template>
  <header id="header" class="site-header white" :class="{'lime':green}">
    <div class="header__grid">
      <div class="header__grid__logo">
        <a href="https://lessonpro.co.uk">
          <img v-if="green" src="@/assets/logo-white.png"/>
          <img v-else src="@/assets/logo-black.png"/>
        </a>
      </div>
      <div class="header__grid__nav">
        <nav class="primary">
          <ul id="primary-menu" class="menu">
            <li class="menu-item-has-children">
              <a href="https://lessonpro.co.uk/why-lesson-pro/">Why Lesson Pro</a>
              <div class="sub-menu-container">
                <div class="sub-menu-inner">
                  <ul class="sub-menu">
                    <li class="level-1"><a href="https://lessonpro.co.uk/why-lesson-pro/golf-professionals/">Golf Professionals</a></li>
                    <li class="level-1"><a href="https://lessonpro.co.uk/why-lesson-pro/players/">For Players</a></li>
                  </ul>
                </div>
              </div>
            </li>
            <li><a href="https://lessonpro.co.uk/pricing">Pricing</a></li>
            <li><a href="https://lessonpro.co.uk/support">Support</a></li>
            <li><a href="https://lessonpro.co.uk/news">News</a></li>
          </ul>
        </nav>
        <div class="nav-toggle">
          <div class="nav-toggle__inner">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div class="header__grid__signup">
        <a href="https://lessonpro.co.uk/contact-us">
          Contact
        </a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'navigation',
  props: ['green']
}
</script>
