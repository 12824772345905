<template>
  <footer class="site-footer">
    <div class="container">
      <div class="footer__grid">
        <div class="footer__grid__logo">
          <a href="https://lessonpro.co.uk">
            <img src="@/assets/logo-white.png"/>
          </a>
        </div>
        <div class="footer__grid__nav">
          <nav class="footer">
            <ul class="menu">
              <li><a href="https://lessonpro.co.uk/why-lesson-pro/">Why Lesson Pro</a></li>
              <li><a href="https://lessonpro.co.uk/pricing/">Pricing</a></li>
              <li><a href="https://lessonpro.co.uk/support/">Support</a></li>
            </ul>
          </nav>
        </div>
        <div class="footer__grid__legal">
          <nav class="legal">
            <ul class="menu">
              <li><a href="https://lessonpro.co.uk/privacy/">Privacy</a></li>
              <li><a href="https://lessonpro.co.uk/terms/">Terms</a></li>
            </ul>
          </nav>
        </div>
        <div class="footer__grid__contact">
          <ul>
            <li><a href="https://lessonpro.co.uk/contact-us">Contact us</a></li>
            <li><a href="mailto:info@lessonpro.co.uk">info@lessonpro.co.uk</a></li>
          </ul>
        </div>

        <div class="footer__grid__smallprint">
          © Lesson Pro Limited 2021. All rights reserved.
        </div>

        <div class="footer__grid__socials">
          <ul>
            <li><a class="twitter" href="https://twitter.com/lessonprogolf" target="_blank"></a></li>
            <li><a class="facebook" href="https://www.facebook.com/lessonprogolf" target="_blank"></a></li>
            <li><a class="instagram" href="https://www.instagram.com/lessonprogolf/" target="_blank"></a></li>
            <li><a class="youtube" href="https://www.youtube.com/channel/UCgDQjjmj75tKg2N2qKpbNUQ" target="_blank"></a></li>
          </ul>
        </div>
      </div>


    </div>
  </footer>
</template>

<script>
export default {
  name: 'footer-nav'
}
</script>
