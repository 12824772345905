<template>
   <div class="home">
<div class="overlay">
    <div class="overlay__grid">
      <div class="overlay__close"></div>
      <div class="overlay__signup">
        <a href="https://lessonpro.co.uk/contact-us">
          Contact
        </a>
      </div>
    </div>
    <nav class="overlay__nav">
      <ul class="menu">
        <li><a href="https://lessonpro.co.uk/why-lesson-pro">Why Lesson Pro</a></li>
        <li><a href="https://lessonpro.co.uk/pricing/">Pricing</a></li>
        <li><a href="https://lessonpro.co.uk/support/">Support</a></li>
        <li><a href="https://lessonpro.co.uk/news/">News</a></li>
      </ul>
    </nav>
    <div class="overlay__info">
        <a class="email" href="mailto:info@lessonpro.co.uk">info@lessonpro.co.uk</a>
      <div class="overlay__info__socials">
        <ul>
          <li><a class="twitter" href="https://twitter.com/lessonprogolf" target="_blank"></a></li>
          <li><a class="facebook" href="https://www.facebook.com/lessonprogolf" target="_blank"></a></li>
          <li><a class="instagram" href="https://www.instagram.com/lessonprogolf/" target="_blank"></a></li>
          <li><a class="youtube" href="https://www.youtube.com/channel/UCgDQjjmj75tKg2N2qKpbNUQ" target="_blank"></a></li>
        </ul>
      </div>
    </div>
  </div>

  <navigation />
  <div class="prop"></div>

  <main class="content white">
    <div class="container">
      <div class="content__grid">

        <div class="content__grid__col1">

           <div class="content__title">
            &nbsp;
          </div>
         
        </div>
        <div class="content__grid__col2">

          <div class="content__form">

            <div class="content__title">
            Your link has expired!
          </div>


          <div v-if="!done">

               <label for="">To be able to get paid out from lesson pro enter your log in details below.</label>

              <form @submit.prevent="sendForm">
               <input type="email" :class="{ 'invalid': form.errors.has('email') }" placeholder="Email address" v-model="form.email">
               <has-error :form="form" field="email"/>

              <password-input v-model="form.password" placeholder="Password" :class="{ 'invalid': form.errors.has('password') }"/>
              <has-error :form="form" field="password"/>

              <button :disabled="form.busy" type="submit">{{(form.busy) ? 'Please wait...' : 'Next'}}</button>

              </form>

          </div>

          <div v-else>
              <label>Click the button below to be taken to Stripe (our payment provider). Fill in your details with them so that you can get paid for your lessons into your bank account.</label>
                  <br>
                 <a :href="stripe_url" class="button btn--arrow-right btn--secondary">Take me to Stripe</a>
          </div>

              
            

          </div>

        </div>
      </div>
    </div>
  </main>

  <footer-nav />
  </div>
</template>

<script>
import PasswordInput from '@/components/password-input'
import {Form, HasError} from 'vform';
import Navigation from '@/components/navigation'
import FooterNav from '@/components/footer-nav'
export default {
  components: {PasswordInput, Navigation, FooterNav, HasError},
  data(){
    return {
      done: false,
      stripe_url: false,
      form : new Form({
        email: '',
        password: ''
      })
    }
  },
  methods: {
    sendForm(){
      this.form.post('/pro-signup/stripe-expired').then((response)=>{
        this.stripe_url = response.data.url;
        this.done = true;
      })
    }
  } 
}
</script>

