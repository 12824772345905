<template lang="html">
    <div class="passwordInput">
      
        <div @click.prevent="toggle">
          <eye-off-icon v-if="!show" class="icon"></eye-off-icon>
          <eye-icon v-else class="icon"></eye-icon>
        </div>

        <input v-if="!show" type="password" v-model="inputVal" :data-cy="name" :placeholder="placeholder">
        <input v-else type="text" v-model="inputVal" :data-cy="name" :placeholder="placeholder">
    </div>
</template>

<script>
import { EyeIcon, EyeOffIcon } from 'vue-feather-icons'
export default {
  name: 'password-input',
  components: {EyeIcon, EyeOffIcon},
  props: ['value','name','placeholder'],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  data(){
    return {
      show:false
    }
  },
  methods:{
    toggle(){
      return this.show = !this.show;
    }
  }
}
</script>

<style lang="css" scoped>
.passwordInput{
  position:relative;
}
.passwordInput .icon{
  position:absolute;
      right: 20px;
    top: 15px;
    cursor:pointer;
    color:#61c45c;
}
</style>
