<template>
   <div class="complete">
      
  <div class="overlay">
    <div class="overlay__grid">
      <div class="overlay__close"></div>
      <div class="overlay__signup">
        <a href="https://lessonpro.co.uk/contact-us">
          Contact
        </a>
      </div>
    </div>
    <nav class="overlay__nav">
      <ul class="menu">
        <li><a href="https://lessonpro.co.uk/why-lesson-pro">Why Lesson Pro</a></li>
        <li><a href="https://lessonpro.co.uk/pricing">Pricing</a></li>
        <li><a href="https://lessonpro.co.uk/support">Support</a></li>
        <li><a href="https://lessonpro.co.uk/news">News</a></li>
      </ul>
    </nav>
    <div class="overlay__info">
        <a class="email" href="mailto:info@lessonpro.co.uk">info@lessonpro.co.uk</a>
      <div class="overlay__info__socials">
        <ul>
          <li><a class="twitter" href="https://twitter.com/lessonprogolf" target="_blank"></a></li>
          <li><a class="facebook" href="https://www.facebook.com/lessonprogolf" target="_blank"></a></li>
          <li><a class="instagram" href="https://www.instagram.com/lessonprogolf/" target="_blank"></a></li>
          <li><a class="youtube" href="https://www.youtube.com/channel/UCgDQjjmj75tKg2N2qKpbNUQ" target="_blank"></a></li>
        </ul>
      </div>
    </div>
  </div>

  <navigation :green="true"/>

  <div class="prop"></div>

  <main class="content lime">
    <div class="container">
      <div class="content__grid">

        <div class="content__grid__col1">
          <div class="content__subtitle">
            Golf Professional Sign Up
          </div>
          <div class="content__title">
            Almost there!
          </div>
          <p>
            Thank you for setting up your Lesson Pro and Stripe accounts. Please 
            download the app by following the links below and login using your 
            account email address and password to complete your subscription.
          </p>
          <div class="content__download">
            <a href="https://apps.apple.com/app/id1550581581" target="_blank">
              <img src="@/assets/3.png" alt="">
            </a>
            <a href="" target="_blank">
              <img src="@/assets/4.png" alt="">
            </a>
          </div>

        </div>
        <div class="content__grid__col2">

          <img class="desktop" src="@/assets/2.png" alt="">
          <img class="tablet" src="@/assets/5.png" alt="">

        </div>
      </div>
    </div>
  </main>

  <footer-nav />
  </div>
</template>

<script>
import Navigation from '@/components/navigation'
import FooterNav from '@/components/footer-nav'
export default {
    components: {Navigation, FooterNav}
}
</script>

