import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Invalid from '../views/Invalid.vue'
import Complete from '../views/Complete.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/complete',
    name: 'Complete',
    component: Complete
  },
  {
    path: '/invalid',
    name: 'Invalid',
    component: Invalid
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
