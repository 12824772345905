import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import '@/scss/screen.scss'

window.axios = require('axios');
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
import VueCurrencyInput from 'vue-currency-input'
const VueCurrencyInputOptions = {
  globalOptions: { currency: 'GBP'}
}
Vue.use(VueCurrencyInput,VueCurrencyInputOptions);

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css'; 
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
